import {
    API_CREATE_PLANNING,
    API_GET_PLANNING_DETAIL,
    API_GET_LIST_PLAN,
    API_UPDATE_PLAN,
    API_DETELE_PLAN,
    API_RESOURCE_V2,
    API_PLAN_V2,
} from "@/apis/api-endpoint";
import Fetch from "@/helpers/fetch";
class planningService {
    async createPlanning(payload) {
        const result = await Fetch.make().post(API_CREATE_PLANNING.replace("v1", 'v2'), payload, {
            authHeader: true,
        });
        return result;
    }

    async getPlanningDetail(id) {
        const url = API_GET_PLANNING_DETAIL.replace(":id", id).replace("v1", 'v2');
        const result = await Fetch.make().get(url, { authHeader: true });
        return result;
    }

    async getPlannings(query) {
        const result = await Fetch.make().get(API_GET_LIST_PLAN, {
            authHeader: true,
            query,
        });
        return result;
    }

    async updatePlanning(payload) {
        const url = API_UPDATE_PLAN.replace(":id", payload.id).replace("v1", 'v2');
        const result = await Fetch.make().put(url, payload, {
            authHeader: true,
        });
        return result;
    }
    async deletePlanning(id) {
        const url = API_DETELE_PLAN.replace(":id", id);
        const result = await Fetch.make().delete(url, { authHeader: true });
        return result;
    }

    async uploadPlanAvatar(formData) {
        const url = `${API_RESOURCE_V2}/images/plans`;
        const myHeaders = new Headers();
        myHeaders.append(
            "Authorization",
            "Bearer " + localStorage.getItem("ADSBASE-TOKEN")
        );
        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formData,
            redirect: "follow",
        };

        const result = await fetch(url, requestOptions);
        const data = await result.json();
        return data;
    }

    async maskAsDone (id) {
        const url = `${API_PLAN_V2}/:id/markAsDone`.replace(":id", id);
        const result = await Fetch.make().put(url, {}, {
            authHeader: true,
        });
        return result;
    }
}

export default new planningService();
