import { handleCallApi } from "@/helpers/api-method";
import PostService from "@/services/post.service";
import { safeJsonParse } from "@/helpers/utils";
export const state = {
    posts: {
        status: '',
        data: [],
        message: '',
    },

    createPost: {
        status: '',
        data: [],
        message: '',
    },
    
    createPostAndReview: {
        status: '',
        data: [],
        message: '',
    },

    createContent: {
        status: '',
        data: [],
        message: '',
    },

    renderImage: {
        status: '',
        data: [],
        message: '',
    },

    renderTags: {
        status: '',
        data: [],
        message: '',
    },

    uploadImage: {
        status: '',
        data: [],
        message: '',
    },

    removeImage: {
        status: '',
        data: [],
        message: '',
    },

    postView: {
        blocks: [],
        image: [],
    },

    images: [],
    detail: {
        status: '',
        data: [],
        message: '',
    },

    detailCustomerReview: {
        status: '',
        data: [],
        message: '',
    },
    
    contentReviewHtml: '',
    statusFacebookBackground: '',
    typePost: '',
    
    detailDashboard: {
        status: '',
        data: [],
        message: '',
    },
};

export const mutations = {
    CHANGE_POST(state, value) {
        state.posts.data = value.data;
        state.posts.status = value.status;
        state.posts.message = value.message;
    },

    CHANGE_CREATE_POST(state, value) {
        state.createPost.data = value.data;
        state.createPost.status = value.status;
        state.createPost.message = value.message;
    },
    CHANGE_CREATE_POST_AND_REVIEW(state, value) {
        state.createPostAndReview = value;
    },

    CHANGE_CREATE_CONTENT(state, value) {
        state.createContent.data = value.data;
        state.createContent.status = value.status;
        state.createContent.message = value.message;
    },

    CHANGE_RENDER_IMAGE(state, value) {
        state.renderImage.data = value.data;
        state.renderImage.status = value.status;
        state.renderImage.message = value.message;
    },

    CHANGE_RENDER_TAGS(state, value) {
        state.renderTags.data = value.data;
        state.renderTags.status = value.status;
        state.renderTags.message = value.message;
    },

    CHANGE_UPLOAD_IMAGE(state, value) {
        state.uploadImage.data = value.data;
        state.uploadImage.status = value.status;
        state.uploadImage.message = value.message;
    },

    CHANGE_REMOVE_IMAGE(state, value) {
        state.removeImage.data = value.data;
        state.removeImage.status = value.status;
        state.removeImage.message = value.message;
    },

    CHANGE_POST_VIEW(state, value) {
        state.postView.blocks = value
    },

    CHANGE_IMAGE(state, value) {
        state.postView.image = value
    },

    CHANGE_DETAIL(state, value) {
        const newData = {
            ...value.data,
            content : safeJsonParse(value.data.content, [])
        }
        state.detail.data = newData;
        state.detail.status = value.status;
        state.detail.message = value.message;
    },

    CHANGE_DETAIL_CUSTOMER_REVIEW(state, value) {
        const newData = {
            ...value.data,
            content : safeJsonParse(value.data.content, [])
        }
        state.detailCustomerReview.data = newData;
        state.detailCustomerReview.status = value.status;
        state.detailCustomerReview.message = value.message;
    },

    CHANGE_CONTENT_REVIEW_HTML(state, value) {
        state.contentReviewHtml = value
    },

    CHANGE_STATUS_FACEBOOK_BACKGROUND(state, value) {
        state.statusFacebookBackground = value
    },

    CHANGE_TYPE_POST(state, value) {
        state.typePost = value
    },

    CHANGE_DETAIL_DASHBOARD(state, value) {
        state.detailDashboard = value;
    },
};

export const actions = {
    async getPosts({ commit }) {
        await handleCallApi(PostService.getPosts, (value) => commit('CHANGE_POST', value));
    },

    async createPost({ commit }, data) {    
        await handleCallApi(() => PostService.createPost(data), (value) => commit('CHANGE_CREATE_POST', value));
    },
    async createPostAndReview({ commit }, data) {    
        await handleCallApi(() => PostService.createPost(data), (value) => commit('CHANGE_CREATE_POST_AND_REVIEW', value));
    },

    async createContent({ commit }, data) {
        await handleCallApi(() => PostService.createContent(data), (value) => commit('CHANGE_CREATE_CONTENT', value));
    },

    async renderImage({ commit }, payload) {    
        await handleCallApi(() => PostService.renderImage(payload), (value) => commit('CHANGE_RENDER_IMAGE', value));
    },

    async renderTags({ commit }) {    
        await handleCallApi(PostService.renderTags, (value) => commit('CHANGE_RENDER_TAGS', value));
    },

    async uploadImages({ commit }, payload) {    
        await handleCallApi(()=>PostService.uploadImages(payload), (value) => commit('CHANGE_UPLOAD_IMAGE', value));
    },

    async removeImage({ commit }, payload) {    
        await handleCallApi(()=>PostService.removeImage(payload), (value) => commit('CHANGE_REMOVE_IMAGE', value));
    },

    async setPostView({ commit }, value) {
        commit('CHANGE_POST_VIEW', value)
    },

    async setImages({ commit }, value) {
        commit('CHANGE_IMAGE', value)
    },

    async getDetail({ commit }, id) {
        await handleCallApi(() => PostService.getDetail(id), (value) => commit('CHANGE_DETAIL', value));
    },

    async getDetailCustomerReview({ commit }, payload) {
        await handleCallApi(() => PostService.getDetailCustomerReview(payload), (value) => commit('CHANGE_DETAIL_CUSTOMER_REVIEW', value));
    },
    
    async setContentReivewHtml({ commit }, value) {
        commit('CHANGE_CONTENT_REVIEW_HTML', value)
    },

    async setStatusFacebookBackground({ commit }, value) {
        commit('CHANGE_STATUS_FACEBOOK_BACKGROUND', value)
    },

    async setTypePost({ commit }, value) {
        commit('CHANGE_TYPE_POST', value)
    },

    async getDetailDashboard({ commit }, id) {    
        await handleCallApi(() => PostService.getDetailDashboard(id), (value) => commit('CHANGE_DETAIL_DASHBOARD', value));
    },
};