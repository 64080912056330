import moment from "moment";
moment.locale("vi");
export function getFirstLetter(name) {
    // Split the full name into an array of words
    const nameArray = name.split(" ");

    // Get the last word and extract its first letter
    const result = nameArray[nameArray.length - 1].charAt(0).toUpperCase();

    return result;
}

export const converTimestampToDate = (timestamp) => {
    if (checkEmpty(timestamp)) return "-";
    const isValidate = moment.unix(timestamp).isValid();
    if (!isValidate) {
        return "-";
    }
    const formattedDate = moment.unix(timestamp).format("DD/MM/YYYY");

    return formattedDate;
};

export const convertTimestampToDateTime = (
    timestamp,
    format = "DD/MM/YYYY HH:mm"
) => {
    if (checkEmpty(timestamp)) {
        return "-";
    }

    if (!moment.unix(timestamp).isValid()) {
        return "-";
    }

    return moment.unix(timestamp).format(format);
};

export const humanizeDate = (timestamp) => {
    if (checkEmpty(timestamp)) {
        return "-";
    }

    if (!moment.unix(timestamp).isValid()) {
        return "-";
    }

    return moment.unix(timestamp).fromNow();
};

export function checkEmpty(value) {
    /**
     * Kiểm tra xem một giá trị có rỗng không.
     *
     * @param {*} value - Giá trị cần kiểm tra.
     * @returns {boolean} - True nếu giá trị là rỗng, ngược lại False.
     */
    if (
        value === undefined ||
        value === null ||
        value === "" ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "object" && Object.keys(value).length === 0)
    ) {
        return true;
    }
    return false;
}

export function convertDateToTimestamp(dateString) {
    if (
        checkEmpty(dateString) &&
        !moment(dateString, "DD/MM/YYYY", true).isValid()
    ) {
        return "";
    }

    // Chuyển đổi chuỗi ngày tháng sang đối tượng Moment
    const date = moment(dateString);

    // Kiểm tra nếu ngày tháng hợp lệ
    if (!date.isValid()) {
        throw new Error("Invalid date");
    }

    // Trả về timestamp (giây)
    return date.unix();
}

export function checkStringLenght(text, maxlenght = 255) {
    return text.length > maxlenght;
}

function renderBlockToHtml(block) {
    switch (block.type) {
        case "header":
            return `<h${block.data?.level || 1} style="text-align:${
                block?.tunes?.textAlignment?.alignment || "left"
            }">${block.data?.text}</h${block.data?.level || 1}>`;
        case "paragraph":
            return `<p style="text-align:${
                block.tunes?.textAlignment.alignment || "left"
            }">${block.data?.text}</p>`;
        case "image":
            return `<figure><img src="${block.data?.file?.url}" alt="${
                block.data?.caption || ""
            }"><figcaption>${block.data?.caption || ""}</figcaption></figure>`;
        case "quote":
            return `<blockquote><p>${block.data?.text}</p><cite>${
                block.data?.caption || ""
            }</cite></blockquote>`;
        case "warning":
            return `<div class="warning"><strong>${block.data?.title}</strong><p>${block.data?.message}</p></div>`;
        case "delimiter":
            return `<hr>`;
        case "checklist":
            return `<ul>${block.data?.items
                .map((item) => `<li>${item.text}</li>`)
                .join("")}</ul>`;
        case "list":
            //eslint-disable-next-line
            const tag = block.data?.style === "ordered" ? "ol" : "ul";
            return `<${tag}>${block.data?.items
                .map((item) => `<li>${item}</li>`)
                .join("")}</${tag}>`;
        case "embed":
            return `<div class="embed"><iframe src="${
                block.data?.embed
            }" width="${block.data?.width}" height="${
                block.data?.height
            }" frameborder="0" allowfullscreen></iframe><figcaption>${
                block.data?.caption || ""
            }</figcaption></div>`;
        case "underline":
            return `<u>${block.data?.text}</u>`;
        case "strikethrough":
            return `<s>${block.data?.text}</s>`;
        case "inlineCode":
            return `<code>${block.data?.code}</code>`;
        case "changeCase":
            return `<span>${block.data?.text}</span>`;
        case "textAlignment":
            return `<div class="text-${block.data?.alignment}">${block.data?.text}</div>`;
        case "title":
            return `<${block.data?.titleType || "h6"} style="color:${
                block.data?.color || "#000"
            }; text-align:${block.data?.alignText?.split("-")[2] || "left"}">${
                block.data?.text
            }</${block.data?.titleType || "h6"}>`;
        default:
            return ""; // Trả về chuỗi rỗng nếu block không xác định
    }
}

export function renderEditorJsToHtml(editorData) {
    return editorData.map(renderBlockToHtml).join("");
}

export function safeJsonParse(str, defaultValue = "") {
    try {
        return JSON.parse(str);
    } catch (error) {
        console.error("Lỗi khi phân tích chuỗi JSON:", error.message);
        return defaultValue; // Hoặc giá trị mặc định khác bạn muốn trả về
    }
}

export const convertHtmlToParagraphs = (html) => {
    // Tạo một container tạm thời để chứa HTML
    const tempContainer = document.createElement("div");
    tempContainer.innerHTML = html;

    // Lấy tất cả các phần tử con bên trong container tạm thời
    const children = Array.from(tempContainer.childNodes);

    // Tạo một mảng để chứa các đoạn văn bản
    const paragraphs = [];

    // Lặp qua từng phần tử con và tạo các đoạn văn bản
    children.forEach((child) => {
        if (
            child.nodeType === Node.ELEMENT_NODE ||
            child.nodeType === Node.TEXT_NODE
        ) {
            const text = child.textContent.trim();
            if (text) {
                paragraphs.push(`<p>${text}</p>`);
            }
        }
    });

    // Ghép các đoạn văn bản thành một chuỗi HTML
    return paragraphs.join("");
};

export const convertDataToOption = (data, label = "name", value = "id") => {
    if (!Array.isArray(data)) return [];
    return data?.map((item) => {
        return {
            ...item,
            label: item[label],
            value: item[value],
        };
    });
};

export const convertConfigToOption = (
    config,
    label = "label",
    value = "value"
) => {
    return Object.keys(config).map((key) => {
        return {
            label: config[key][label],
            value: config[key][value],
            ...config[key],
        };
    });
};

export function convertTimestampToTime(timestamp) {
    // Chuyển đổi timestamp thành đối tượng moment
    const date = moment.unix(timestamp);

    // Định dạng đối tượng moment để lấy giờ
    return date.format("HH:mm");
}

export const checkPermission = (data, permission) => {
    return data?.includes(permission);
};

export const ScreenHelper = (() => {
    const mobileBreakpoint = 576;

    function isMobile() {
        return window.innerWidth <= mobileBreakpoint;
    }

    function checkScreenSize(callback) {
        if (typeof callback === "function") {
            callback(isMobile());
        }
    }

    function initialize(callback) {
        checkScreenSize(callback);
        // Kiểm tra khi vào trang
        window.addEventListener("load", () => checkScreenSize(callback));

        // Kiểm tra khi thay đổi kích thước trang
        window.addEventListener("resize", () => checkScreenSize(callback));
    }

    return {
        isMobile,
        checkScreenSize,
        initialize,
    };
})();

export function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng từ 0-11, cần cộng thêm 1
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}`;
}

export function upperCaseAll(str) {
    var words = str.replace("_", " ").split(" ");

    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }

    return words.join(" ");
}

export function getAspectRatioType(aspectRatio) {
    if (aspectRatio < 1) {
        return "12";
    } else if (aspectRatio > 1.4) {
        return "21";
    } else {
        return "11";
    }
}

export function cleanEmptyParagraphs(htmlString) {
    if (checkEmpty(htmlString)) return htmlString;
    // Thêm margin-bottom vào các thẻ <p></p> trống
    return (
        htmlString
            // Thay thế các cặp <p></p> liên tiếp thành một với margin-bottom
            .replace(
                /(<p>\s*<\/p>\s*){2,}/g,
                "<p style='margin-bottom: 12px;'></p>"
            )
            // Thêm margin-bottom vào những thẻ <p></p> trống riêng lẻ
            .replace(/<p>\s*<\/p>/g, "<p style='margin-bottom: 12px;'></p>")
    );
}

// export function cleanEmptyParagraphs(htmlString) {
//     // Thêm margin-bottom vào các thẻ <p></p> trống
//     return htmlString
//         // Thay thế các cặp <p></p> liên tiếp thành một với margin-bottom
//         .replace(/(<p>\s*<\/p>\s*){2,}/g, "<p style='margin-bottom: 12px;'></p>")
//         // Thêm margin-bottom vào những thẻ <p></p> trống riêng lẻ
//         .replace(/<p>\s*<\/p>/g, "<p style='margin-bottom: 12px;'></p>")
//         // Xóa tất cả các thẻ <img>
//         .replace(/<img\b[^>]*>/g, "");
// }

export function validatePhoneNumber(phoneNumber) {
    // Loại bỏ khoảng trắng và ký tự đặc biệt khỏi số điện thoại
    const cleanedNumber = phoneNumber.replace(/[\s-]/g, "");

    // Kiểm tra định dạng của số điện thoại
    const isValid = /^0\d{9,10}$/.test(cleanedNumber);

    return isValid;
}

export function convertDataV1ToV2(data) {
    const result = [];
    const defaultAttributes = {
        background: "#ffffff",
        color: "#212529",
    };

    data?.forEach((item) => {
        // Bỏ qua các phần tử có type là "image"
        if (item.type === "image") return;

        // Nếu type là "paragraph", xử lý text và chuyển đổi sang định dạng mới
        if (item.type === "paragraph" && item.data && item.data.text) {
            const paragraphs = item.data.text.split("<br>");

            paragraphs.forEach((paragraph) => {
                result.push({
                    attributes: defaultAttributes,
                    insert: paragraph.replace(/&nbsp;/g, " "),
                });
                result.push({ insert: "\n" }); // Thêm dòng mới sau mỗi đoạn
            });
        }
    });

    return result;
}

export function convertToHtml(data) {
    let html = "";

    data.forEach((item) => {
        // Nếu phần tử là text thì thêm nó vào HTML
        if (item.insert && item.insert !== "\n") {
            html += `<p style="background: ${item.attributes.background}; color: ${item.attributes.color};">${item.insert}</p>`;
        }
        // Nếu là dòng mới "\n" thì chuyển thành thẻ <br>
        // else if (item.insert === "\n") {
        //     html += "<br>";
        // }
    });
    return html;
}

export function convertNumber(number) {
    if (typeof number !== "number") {
        throw new Error("Input must be a number");
    }
    return number.toLocaleString("en-US");
}
