import { createWebHistory, createRouter } from "vue-router";
import routes from "./routes";
import _isEmpty from "lodash/isEmpty";
import store from "@/state/store";
const router = createRouter({
    history: createWebHistory(),
    routes,
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { top: 0, left: 0 };
        }
    },
});

router.beforeEach((to, from, next) => {
    const public_pages_auth = [
        "login",
        "forgot-password",
        "forgot-cid",
        "reset-password",
    ];

    const public_pages = [
        "customer-review",
    ];
    

    const access_token = localStorage.getItem("reset_token");
    const urlParams = new URLSearchParams(window.location.search);
    const accessTokenURL = urlParams.get("reset_token");

    const token = localStorage.getItem("ADSBASE-TOKEN");
    const authRequired = !public_pages_auth.includes(to.name);
    const authPublic = public_pages.includes(to.name);
    if (_isEmpty(token)) {
        if(authPublic){
            return next();
        }

        if (!authRequired) {
            if (!access_token && to.name === "reset-password") {
                if (!accessTokenURL) {
                    next({ name: "login" });
                    return;
                }
            }
            return next();
        } else {
            return next({ name: "login" });
        }
    } else {
        if (!authRequired) {
            // nhớ sau này sửa lại thành !authRequired
            return next({ name: "loading" });
        } else {
            return next();
        }
    }
});

router.beforeEach((to, from, next) => {
    const access_token = localStorage.getItem("reset_token");
    const urlParams = new URLSearchParams(window.location.search);
    const accessTokenURL = urlParams.get("reset_token");

    if (!access_token && to.name === "reset-password") {
        if (!accessTokenURL) {
            return next({ name: "login" });
        }
    }

    return next();
});

router.beforeEach((to, from, next) => {
    if (to.name != "categories-detail") {
        sessionStorage.removeItem("tab-detail");
        return next();
    }

    return next();
});

router.beforeResolve(async (to, from, next) => {
    try {
        for (const route of to.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(to, from, (...args) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error("Redirected"));
                        } else {
                            resolve();
                        }
                    });
                } else {
                    resolve();
                }
            });
        }
    } catch (error) {
        return;
    }
    document.title = to.meta.title;
    return next();
});

router.beforeEach((to, from, next) => {
    const permissions = store.state.auth.permissions.data;
    const requiresAuth = permissions[to.meta.permission];
    if (to.meta.permission == "public") {
        return next();
    }
    else if (_isEmpty(requiresAuth)) {
        return next({ name: "loading" });
    } else {
        return next();
    }
});

export default router;
