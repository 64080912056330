<script>
// import {
//   SimpleBar
// } from "simplebar-vue3";

import i18n from "../i18n";
import { layoutMethods, layoutComputed } from "@/state/helpers";
import { ScreenHelper } from "@/helpers/utils";
import { handleCallApi } from '@/helpers/api-method'
import { getToastError } from '@/helpers/toast'
import authService from "@/services/auth.service";
import get from 'lodash/get'
import { checkEmpty } from '@/helpers/utils';
import Avatar from "@/components/ui/avatar/avatar.vue";
import notificationService from "@/services/notification.service";
import isEmpty from 'lodash/get'
import InfiniteLoading from "v3-infinite-loading";
import moment from "moment/moment";
import taskStatus from '@/config/status/post';
import planningDetailService from "@/services/planning-detail.service";
/**
 * Nav-bar Component
 */
export default {
    data() {
        return {
            languages: [{
                flag: require("@/assets/images/flags/us.svg"),
                language: "en",
                title: "English",
            },
            {
                flag: require("@/assets/images/flags/spain.svg"),
                language: "sp",
                title: "Española",
            },
            {
                flag: require("@/assets/images/flags/germany.svg"),
                language: "gr",
                title: "Deutsche",
            },
            {
                flag: require("@/assets/images/flags/italy.svg"),
                language: "it",
                title: "italiana",
            },
            {
                flag: require("@/assets/images/flags/russia.svg"),
                language: "ru",
                title: "русский",
            },
            {
                flag: require("@/assets/images/flags/china.svg"),
                language: "ch",
                title: "中國人",
            },
            {
                flag: require("@/assets/images/flags/french.svg"),
                language: "fr",
                title: "Français",
            },
            {
                flag: require("@/assets/images/flags/ae.svg"),
                language: "ar",
                title: "Arabic",
            },
            ],
            lan: i18n.locale,
            text: null,
            flag: null,
            value: null,
            myVar: 1,
            layoutOptions: {
                "twocolumn": {
                    label: 'Left Menu',
                    value: "twocolumn",
                    icon: 'ri-layout-left-2-line'
                },
                "horizontal": {
                    label: 'Top Menu',
                    value: "horizontal",
                    icon: 'ri-layout-top-2-line'
                },
            },
            isLogout: false,
            notifications: [],
            currentPage: 1,
            limit: 10,
            readNotify: {
                status: '',
                message: '',
                data: {}
            },
            unRead: {
                status: '',
                message: '',
                data: {
                    count: 0,
                }
            },
            unReadInterval: null,
            isIntervalUnRead: localStorage.getItem("isIntervalUnRead") || false,
            isMobile: false,
            openNoti: false,
            unReadChecked: false,
            taskStatus,
            isReadAll: false,
            isReadAllSuccess: false
        };
    },
    components: {
        Avatar,
        InfiniteLoading
    },

    methods: {
        ...layoutMethods,
        _get(obj, path, defaultValue) {
            if (checkEmpty(get(obj, path, defaultValue))) {
                return defaultValue
            }
            return get(obj, path, defaultValue);
        },

        _isEmpty(obj) {
            return isEmpty(obj)
        },

        _moment(date) {
            return moment(date)
        },

        getRoles(roles) {
            if (checkEmpty(roles)) {
                return '-'
            }
            return roles.join(', ')
        },
        isCustomDropdown() {
            //Search bar
            //       var searchOptions = document.getElementById("search-close-options");
            //       var dropdown = document.getElementById("search-dropdown");
            //       var searchInput = document.getElementById("search-options");

            //       searchInput.addEventListener("focus", () => {
            //         var inputLength = searchInput.value.length;
            //         if (inputLength > 0) {
            //           dropdown.classList.add("show");
            //           searchOptions.classList.remove("d-none");
            //         } else {
            //           dropdown.classList.remove("show");
            //           searchOptions.classList.add("d-none");
            //         }
            //       });

            //       searchInput.addEventListener("keyup", () => {
            //         var inputLength = searchInput.value.length;
            //         if (inputLength > 0) {
            //           dropdown.classList.add("show");
            //           searchOptions.classList.remove("d-none");
            //         } else {
            //           dropdown.classList.remove("show");
            //           searchOptions.classList.add("d-none");
            //         }
            //       });

            //       searchOptions.addEventListener("click", () => {
            //         searchInput.value = "";
            //         dropdown.classList.remove("show");
            //         searchOptions.classList.add("d-none");
            //       });

            //       document.body.addEventListener("click", (e) => {
            //         if (e.target.getAttribute("id") !== "search-options") {
            //           dropdown.classList.remove("show");
            //         //   searchOptions.classList.add("d-none");
            //         }
            //       });
        },

        toggleHamburgerMenu() {
            var windowSize = document.documentElement.clientWidth;
            let layoutType = document.documentElement.getAttribute("data-layout");
            let visiblilityType = document.documentElement.getAttribute("data-sidebar-visibility");

            document.documentElement.setAttribute("data-sidebar-visibility", "show");

            if (windowSize > 767)
                document.querySelector(".hamburger-icon").classList.toggle("open");

            //For collapse horizontal menu
            if (
                document.documentElement.getAttribute("data-layout") === "horizontal"
            ) {
                document.body.classList.contains("menu") ?
                    document.body.classList.remove("menu") :
                    document.body.classList.add("menu");
            }

            //For collapse vertical menu
            if (visiblilityType === "show" && (layoutType === "vertical" || layoutType === "semibox")) {
                if (windowSize < 1025 && windowSize > 767) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "sm" ?
                        document.documentElement.setAttribute("data-sidebar-size", "") :
                        document.documentElement.setAttribute("data-sidebar-size", "sm");
                } else if (windowSize > 1025) {
                    document.body.classList.remove("vertical-sidebar-enable");
                    document.documentElement.getAttribute("data-sidebar-size") == "lg" ?
                        document.documentElement.setAttribute("data-sidebar-size", "sm") :
                        document.documentElement.setAttribute("data-sidebar-size", "lg");
                } else if (windowSize <= 767) {
                    document.body.classList.add("vertical-sidebar-enable");
                    document.documentElement.setAttribute("data-sidebar-size", "lg");
                }
            }

            //Two column menu
            if (document.documentElement.getAttribute("data-layout") == "twocolumn") {
                document.body.classList.contains("twocolumn-panel") ?
                    document.body.classList.remove("twocolumn-panel") :
                    document.body.classList.add("twocolumn-panel");
            }
        },
        toggleMenu() {
            this.$parent.toggleMenu();
        },
        toggleRightSidebar() {
            this.$parent.toggleRightSidebar();
        },
        initFullScreen() {
            document.body.classList.toggle("fullscreen-enable");
            if (
                !document.fullscreenElement &&
                /* alternative standard method */
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement
            ) {
                // current working methods
                if (document.documentElement.requestFullscreen) {
                    document.documentElement.requestFullscreen();
                } else if (document.documentElement.mozRequestFullScreen) {
                    document.documentElement.mozRequestFullScreen();
                } else if (document.documentElement.webkitRequestFullscreen) {
                    document.documentElement.webkitRequestFullscreen(
                        Element.ALLOW_KEYBOARD_INPUT
                    );
                }
            } else {
                if (document.cancelFullScreen) {
                    document.cancelFullScreen();
                } else if (document.mozCancelFullScreen) {
                    document.mozCancelFullScreen();
                } else if (document.webkitCancelFullScreen) {
                    document.webkitCancelFullScreen();
                }
            }
        },
        //     setLanguage(locale, country, flag) {
        //       this.lan = locale;
        //       this.text = country;
        //       this.flag = flag;
        //       document.getElementById("header-lang-img").setAttribute("src", flag);
        //       i18n.global.locale = locale;
        //     },
        // toggleDarkMode() {
        //   if (document.documentElement.getAttribute("data-layout-mode") == "dark") {
        //     document.documentElement.setAttribute("data-layout-mode", "light");
        //   } else {
        //     document.documentElement.setAttribute("data-layout-mode", "dark");
        //   }
        // },
        initTopbarComponents() {
            function updateCartPrice() {
                var currencySign = "$";
                var subtotal = 0;
                Array.from(document.getElementsByClassName("cart-item-price")).forEach(function (e) {
                    subtotal += parseFloat(e.innerHTML);
                });
                if (document.getElementById("cart-item-total")) {
                    document.getElementById("cart-item-total").innerHTML = currencySign + subtotal.toFixed(2);
                }
            }
            if (document.getElementsByClassName("dropdown-item-cart")) {
                var dropdownItemCart = document.querySelectorAll(".dropdown-item-cart").length;
                Array.from(document.querySelectorAll("#page-topbar .dropdown-menu-cart .remove-item-btn")).forEach(function (item) {
                    item.addEventListener("click", function () {
                        dropdownItemCart--;
                        this.closest(".dropdown-item-cart").remove();
                        Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                            e.innerHTML = dropdownItemCart;
                        });
                        updateCartPrice();
                        if (document.getElementById("empty-cart")) {
                            document.getElementById("empty-cart").style.display = dropdownItemCart == 0 ? "block" : "none";
                        }
                        if (document.getElementById("checkout-elem")) {
                            document.getElementById("checkout-elem").style.display = dropdownItemCart == 0 ? "none" : "block";
                        }
                    });
                });
                Array.from(document.getElementsByClassName("cartitem-badge")).forEach(function (e) {
                    e.innerHTML = dropdownItemCart;
                });
                if (document.getElementById("empty-cart")) {
                    document.getElementById("empty-cart").style.display = "none";
                }
                if (document.getElementById("checkout-elem")) {
                    document.getElementById("checkout-elem").style.display = "block";
                }
                updateCartPrice();
            }

            // notification messages
            if (document.getElementsByClassName("notification-check")) {
                Array.from(document.querySelectorAll(".notification-check input")).forEach(function (element) {
                    element.addEventListener("click", function (el) {
                        el.target.closest(".notification-item").classList.toggle("active");
                    });
                });
            }
        },

        handleChangeLayout(value) {
            this.changeLayoutType({ layoutType: value })
        },

        hiddenHambergerMenu() {
            document.body.classList.remove("menu")
        },

        async handleLogout() {
            this.isLogout = true
            try {
                let result = {
                    status: '',
                    message: '',
                    data: {}
                }
                await handleCallApi(authService.logout, (value) => result = value)

                if ('error' == result?.status) {
                    getToastError(result?.message)
                    return
                }

                if ('success' == result?.status) {
                    this.$router.push({ name: "logout" });
                }

            } catch (error) {
                this.isLogout = false
                console.error(error)
            }
        },

        async infiniteHandler($state) {
            try {
                const result = await notificationService.getNotifications({
                    page: this.currentPage,
                    isRead: this.convertIsReadChecked(this.unReadChecked)
                })
                if(this._get(result, 'data.data.length', 0) < 1) {
                    $state.complete();
                }
                else {
                    this.notifications = this.notifications.concat(this._get(result, 'data.data', []));
                    $state.loaded();
                }
                this.currentPage++
                // this.currentPage = this.currentPage + 1;
                // $state.loaded();
            } catch (error) {
                console.error(error)
                $state.error();
                this.notifications = {}
            }
        },

        async getNotifications() {
            try {
                let result = {
                    status: '',
                    message: '',
                    data: {}
                }
                await handleCallApi(() => notificationService.getNotifications({
                    page: this.currentPage,
                    isRead: this.convertIsReadChecked(this.unReadChecked)
                }), (value) => result = value)

                if ('success' == result?.status) {
                    this.notifications = this._get(result, 'data.data', [])
                }

            } catch (error) {
                console.error(error)
            }
        },

        async getRedirectLink(type, notiId, taskId, postId) {
            switch (type) {
                case 'NORMAL':
                    await this.readNotification(notiId, () => { })
                    return
                case 'REVIEW':
                    await this.readNotification(notiId, () => this.$router.push({ name: 'admin-review', params: { id: postId } }))
                    return
                case 'APPROVE':
                    await this.readNotification(notiId, () => this.$router.push({ name: 'customer-review', params: { id: postId } }))
                    return
                case 'ASSIGN':
                    await this.readNotification(notiId, () => this.startWritingPost(taskId, () => this.$router.push({ name: 'post', params: { id: postId } })))
                    return
                default:
                    return
            }
        },

        async readNotification(id, callback) {
            try {
                await handleCallApi(() => notificationService.readNotification(id), (value) => this.readNotify = value)

                if ('error' == this.readNotify?.status) {
                    getToastError(this.readNotify?.message)
                    return
                }

                if ('success' == this.readNotify?.status) {
                    callback()
                }

            } catch (error) {
                console.error(error)
            }
        },

        async getUnRead() {
            try {
                await handleCallApi(() => notificationService.getUnRead(), (value) => this.unRead = value)
            } catch (error) {
                console.error(error)
            }
        },

        // startWritingPost
        async startWritingPost(taskId, callback) {
            try {
                let result = {
                    status: '',
                    message: '',
                    data: {}
                }
                await handleCallApi(() => planningDetailService.startWritingPost(taskId), (value) => result = value)

                if ('error' == result?.status) {
                    getToastError(result?.message)
                    return
                }

                if ('success' == result?.status) {
                    callback()
                }

            } catch (error) {
                console.error(error)
            }
        },

        convertIsReadChecked(value) {
            if (value) {
                return 1
            }
            return 0
        },

        async readAll() {
            this.isReadAll = true
            await this.readNotification('', () => {
                this.isReadAllSuccess = true
            })
            this.isReadAll = false
        }
    },
    computed: {
        ...layoutComputed,
        profile() {
            return this.$store.state.profile.profile.data
        }
    },
    mounted() {
        ScreenHelper.initialize((isMobile) => {
            this.isMobile = isMobile
            if (isMobile) {
                document.addEventListener("click", this.hiddenHambergerMenu);
            } else {
                document.removeEventListener("click", this.hiddenHambergerMenu);
            }
        });
        //     if (process.env.VUE_APP_I18N_LOCALE) {
        //       this.flag = process.env.VUE_APP_I18N_LOCALE;
        //       this.languages.forEach((item) => {
        //         if (item.language == this.flag) {
        //           document.getElementById("header-lang-img").setAttribute("src", item.flag);
        //         }
        //       });
        //     }

        this.getUnRead()

        document.addEventListener("scroll", function () {
            var pageTopbar = document.getElementById("page-topbar");
            if (pageTopbar) {
                document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add(
                    "topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
            }
        });
        if (document.getElementById("topnav-hamburger-icon"))
            document
                .getElementById("topnav-hamburger-icon")
                .addEventListener("click", this.toggleHamburgerMenu);

        this.isCustomDropdown();
        this.initTopbarComponents();
        document.addEventListener("click", () => {
            this.openNoti = false
        });
    },

    watch: {
        openNoti: {
            handler() {
                if (!this.openNoti) {
                    this.currentPage = 1
                    this.notifications = []
                }
            }
        },
        unReadChecked: {
            handler() {
                this.currentPage = 1
                this.getNotifications()
            }
        },
        $route() {
            this.getUnRead()
        },

        isReadAllSuccess() {
            if (this.isReadAllSuccess) {
                this.getUnRead()
                this.currentPage = 1
                this.getNotifications()
                setTimeout(() => {
                    this.isReadAllSuccess = false
                }, 2000);
            }
        }
    },
};


</script>

<template>
    <header id="page-topbar">
        <div class="layout-width">
            <div class="navbar-header">
                <div class="d-flex">
                    <!-- LOGO -->
                    <div class="navbar-brand-box horizontal-logo">
                        <router-link to="/" class="logo logo-dark">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo/adsbase-logo.png" alt="" height="100" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo/adsbase-logo.png" alt="" height="100" />
                            </span>
                        </router-link>

                        <router-link to="/" class="logo logo-light">
                            <span class="logo-sm">
                                <img src="@/assets/images/logo/adsbase-logo.png" alt="" height="100" />
                            </span>
                            <span class="logo-lg">
                                <img src="@/assets/images/logo/adsbase-logo.png" alt="" height="100" />
                            </span>
                        </router-link>
                    </div>

                    <button type="button" class="
              btn btn-sm
              px-3
              fs-16
              header-item
              vertical-menu-btn
              topnav-hamburger
            " id="topnav-hamburger-icon" @click.stop>
                        <span class="hamburger-icon">
                            <span></span>
                            <span></span>
                            <span></span>
                        </span>
                    </button>

                    <!-- App Search-->
                    <!-- <form class="app-search d-none d-md-block">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Search..." autocomplete="off" id="search-options"
                value="" />
              <span class="mdi mdi-magnify search-widget-icon"></span>
              <span class="
                  mdi mdi-close-circle
                  search-widget-icon search-widget-icon-close
                  d-none
                " id="search-close-options"></span>
            </div>
            <div class="dropdown-menu dropdown-menu-lg" id="search-dropdown">
              <SimpleBar data-simplebar style="max-height: 320px">
                <div class="dropdown-header">
                  <h6 class="text-overflow text-muted mb-0 text-uppercase">
                    Recent Searches
                  </h6>
                </div>

                <div class="dropdown-item bg-transparent text-wrap">
                  <router-link to="/" class="btn btn-soft-secondary btn-sm btn-rounded">how to setup <i
                      class="mdi mdi-magnify ms-1"></i></router-link>
                  <router-link to="/" class="btn btn-soft-secondary btn-sm btn-rounded">buttons <i
                      class="mdi mdi-magnify ms-1"></i></router-link>
                </div>
                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-1 text-uppercase">
                    Pages
                  </h6>
                </div>

                <b-link href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="
                      ri-bubble-chart-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "></i>
                  <span>Analytics Dashboard</span>
                </b-link>

                <b-link href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="ri-lifebuoy-line align-middle fs-18 text-muted me-2"></i>
                  <span>Help Center</span>
                </b-link>

                <b-link href="javascript:void(0);" class="dropdown-item notify-item">
                  <i class="
                      ri-user-settings-line
                      align-middle
                      fs-18
                      text-muted
                      me-2
                    "></i>
                  <span>My account settings</span>
                </b-link>

                <div class="dropdown-header mt-2">
                  <h6 class="text-overflow text-muted mb-2 text-uppercase">
                    Members
                  </h6>
                </div>

                <div class="notification-list">
                  <b-link href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                    <img src="@/assets/images/users/avatar-2.jpg" class="me-3 rounded-circle avatar-xs"
                      alt="user-pic" />
                    <div class="flex-1">
                      <h6 class="m-0">Angela Bernier</h6>
                      <span class="fs-11 mb-0 text-muted">Manager</span>
                    </div>
                  </b-link>
                  <b-link href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                    <img src="@/assets/images/users/avatar-3.jpg" class="me-3 rounded-circle avatar-xs"
                      alt="user-pic" />
                    <div class="flex-1">
                      <h6 class="m-0">David Grasso</h6>
                      <span class="fs-11 mb-0 text-muted">Web Designer</span>
                    </div>
                  </b-link>
                  <b-link href="javascript:void(0);" class="d-flex dropdown-item notify-item py-2">
                    <img src="@/assets/images/users/avatar-5.jpg" class="me-3 rounded-circle avatar-xs"
                      alt="user-pic" />
                    <div class="flex-1">
                      <h6 class="m-0">Mike Bunch</h6>
                      <span class="fs-11 mb-0 text-muted">React Developer</span>
                    </div>
                  </b-link>
                </div>
              </SimpleBar>

              <div class="text-center pt-3 pb-1">
                <router-link to="/pages/search-results" class="btn btn-primary btn-sm">View All Results <i
                    class="ri-arrow-right-line ms-1"></i></router-link>
              </div>
            </div>
          </form> -->
                </div>

                <div class="d-flex align-items-center">
                    <div class="dropdown d-md-none topbar-head-dropdown header-item">
                        <!-- <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-search fs-22"></i>
            </button> -->
                        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-search-dropdown">
                            <form class="p-3">
                                <div class="form-group m-0">
                                    <div class="input-group">
                                        <input type="text" class="form-control" placeholder="Search ..."
                                            aria-label="Recipient's username" />
                                        <b-button variant="primary" type="submit">
                                            <i class="mdi mdi-magnify"></i>
                                        </b-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <!-- <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img id="header-lang-img" src="@/assets/images/flags/us.svg" alt="Header Language" height="20"
                class="rounded" />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <b-link href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
                @click="setLanguage(entry.language, entry.title, entry.flag)"
                :class="{ active: lan === entry.language }" class="dropdown-item notify-item language py-2"
                data-lang="en" title="English">
                <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18" />
                <span class="align-middle">{{ entry.title }}</span>
              </b-link>
            </div>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="bx bx-category-alt fs-22"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
              <div class="
                  p-3
                  border-top-0 border-start-0 border-end-0 border-dashed border
                ">
                <b-row class="align-items-center">
                  <b-col>
                    <h6 class="m-0 fw-semibold fs-15">Web Apps</h6>
                  </b-col>
                  <b-col cols="auto">
                    <b-link href="#!" class="btn btn-sm btn-soft-info">
                      View All Apps
                      <i class="ri-arrow-right-s-line align-middle"></i>
                    </b-link>
                  </b-col>
                </b-row>
              </div>

              <div class="p-2">
                <b-row class="g-0">
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/github.png" alt="Github" />
                      <span>GitHub</span>
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/bitbucket.png" alt="bitbucket" />
                      <span>Bitbucket</span>
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/dribbble.png" alt="dribbble" />
                      <span>Dribbble</span>
                    </b-link>
                  </b-col>
                </b-row>

                <b-row class="g-0">
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/dropbox.png" alt="dropbox" />
                      <span>Dropbox</span>
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/mail_chimp.png" alt="mail_chimp" />
                      <span>Mail Chimp</span>
                    </b-link>
                  </b-col>
                  <b-col>
                    <b-link class="dropdown-icon-item" href="#!">
                      <img src="@/assets/images/brands/slack.png" alt="slack" />
                      <span>Slack</span>
                    </b-link>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>

          <div class="dropdown topbar-head-dropdown ms-1 header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
              id="page-header-cart-dropdown" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-haspopup="true"
              aria-expanded="false">
              <i class='bx bx-shopping-bag fs-22'></i>
              <span
                class="position-absolute topbar-badge cartitem-badge fs-10 translate-middle badge rounded-pill bg-info">5</span>
            </button>
            <div class="dropdown-menu dropdown-menu-xl dropdown-menu-end p-0 dropdown-menu-cart"
              aria-labelledby="page-header-cart-dropdown">
              <div class="p-3 border-top-0 border-start-0 border-end-0 border-dashed border">
                <b-row class="align-items-center">
                  <b-col>
                    <h6 class="m-0 fs-16 fw-semibold"> My Cart</h6>
                  </b-col>
                  <b-col cols="auto">
                    <b-badge variant="soft-warning" class="badge-soft-warning fs-13"><span class="cartitem-badge">7</span>
                      items</b-badge>
                  </b-col>
                </b-row>
              </div>
              <SimpleBar data-simplebar style="max-height: 300px">
                <div class="p-2">
                  <div class="text-center empty-cart" id="empty-cart">
                    <div class="avatar-md mx-auto my-3">
                      <div class="avatar-title bg-soft-info text-info fs-36 rounded-circle">
                        <i class='bx bx-cart'></i>
                      </div>
                    </div>
                    <h5 class="mb-3">Your Cart is Empty!</h5>
                    <b-link href="/ecommerce/products" class="btn btn-success w-md mb-3">Shop Now</b-link>
                  </div>
                  <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-1.png" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                        alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Branded
                            T-Shirts</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>10 x $32</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">320</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm"
                          class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div>

                  <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-2.png" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                        alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Bentwood Chair</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>5 x $18</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">89</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm"
                          class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div>

                  <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-3.png" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                        alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">
                            Borosil Paper Cup</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>3 x $250</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">750</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm"
                          class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div>

                  <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-6.png" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                        alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Gray
                            Styled T-Shirt</b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>1 x $1250</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$ <span class="cart-item-price">1250</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm"
                          class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div>

                  <div class="d-block dropdown-item dropdown-item-cart text-wrap px-3 py-2">
                    <div class="d-flex align-items-center">
                      <img src="@/assets/images/products/img-5.png" class="me-3 rounded-circle avatar-sm p-2 bg-light"
                        alt="user-pic">
                      <div class="flex-1">
                        <h6 class="mt-0 mb-1 fs-14">
                          <b-link href="/ecommerce/product-details" class="text-reset">Stillbird Helmet
                          </b-link>
                        </h6>
                        <p class="mb-0 fs-12 text-muted">
                          Quantity: <span>2 x $495</span>
                        </p>
                      </div>
                      <div class="px-2">
                        <h5 class="m-0 fw-normal">$<span class="cart-item-price">990</span></h5>
                      </div>
                      <div class="ps-2">
                        <b-button type="button" variant="ghost-secondary" size="sm"
                          class="btn btn-icon remove-item-btn"><i class="ri-close-fill fs-16"></i></b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleBar>
              <div class="p-3 border-bottom-0 border-start-0 border-end-0 border-dashed border" id="checkout-elem">
                <div class="d-flex justify-content-between align-items-center pb-3">
                  <h5 class="m-0 text-muted">Total:</h5>
                  <div class="px-2">
                    <h5 class="m-0" id="cart-item-total">$1258.58</h5>
                  </div>
                </div>

                <b-link href="/ecommerce/checkout" class="btn btn-success text-center w-100">
                  Checkout
                </b-link>
              </div>
            </div>
          </div> -->

                    <!-- <div class="ms-1 header-item d-none d-sm-flex">
                        <BDropdown variant="light">
                            <template #button-content>
                                {{ layoutOptions[layoutType].label }}
                            </template>
                            <BDropdownItem v-for="(item, key) in layoutOptions" :key="key"
                                @click="handleChangeLayout(item.value)">
                                <div class="d-flex align-items-center gap-2">
                                    <i :class="item.icon"></i>
                                    {{ item.label }}
                                </div>
                            </BDropdownItem>
                        </BDropdown>
                    </div> -->
                    <router-link to="/plannings/create"
                        v-if="profile?.isAdmin"
                    >
                        <b-button variant="danger">
                            <div class="d-flex align-items-center gap-2">
                                Cấu hình hợp đồng
                            </div>
                        </b-button>
                    </router-link>

                    <div class="ms-1 header-item d-none d-sm-flex">
                        <b-button type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle"
                            data-toggle="fullscreen" @click="initFullScreen">
                            <i class="bx bx-fullscreen fs-22"></i>
                        </b-button>
                    </div>
                    <!-- <div class="ms-1 header-item d-none d-sm-flex">
            <b-button type="button" variant="ghost-secondary" class="btn-icon btn-topbar rounded-circle light-dark-mode"
              @click="toggleDarkMode">
              <i class="bx bx-moon fs-22"></i>
            </b-button>
          </div> -->
                    <div class="header-item position-relative">
                        <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                            @click.stop="openNoti = !openNoti">
                            <i class="bx bx-bell fs-22"></i>
                            <span v-if="_get(unRead, 'data.count', 0) > 0"
                                class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                                {{ _get(unRead, 'data.count', 0) }}<span class="visually-hidden"></span></span>
                        </button>
                        <div class="dropdown-menu p-0" v-if="openNoti" @click.stop
                            :class="isMobile ? '' : 'notification-box-pc d-block'">
                            <div class="dropdown-head bg-secondary bg-pattern rounded-top">
                                <div class="p-3">
                                    <b-row class="align-items-center">
                                        <b-col>
                                            <h6 class="m-0 fs-16 fw-semibold text-white">
                                                Thông báo
                                            </h6>
                                        </b-col>
                                        <b-col cols="auto" class="dropdown-tabs">
                                            <div class="d-flex align-items-center gap-4">
                                                <div class="form-check form-switch form-switch-success">
                                                    <input class="form-check-input" type="checkbox" role="switch"
                                                        id="flexSwitchCheckChecked" v-model="unReadChecked">
                                                    <label class="form-check-label text-white"
                                                        style="transform: translateY(1px); user-select: none"
                                                        for="flexSwitchCheckChecked">Chưa đọc</label>
                                                </div>
                                                <div class="text-white border-0 rounded _link" style="cursor: pointer;"
                                                    :disabled="isReadAll" @click="readAll">
                                                    <div v-if="isReadAllSuccess"
                                                        class="d-flex align-items-center gap-2">
                                                        <i class="ri-check-line"></i>
                                                        Đã đánh dấu
                                                    </div>
                                                    <div v-else> {{ isReadAll ? 'Đánh dấu...' : 'Đánh dấu tất cả đã đọc'
                                                        }}
                                                    </div>
                                                </div>
                                                <button class="border-0 rounded bg-danger text-white"
                                                    @click="openNoti = false">
                                                    <i class="ri-close-line"></i>
                                                </button>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </div>

                                <div class="px-2 pt-2">
                                    <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                                        id="notificationItemsTab" role="tablist">
                                        <li class="nav-item">
                                            <b-link class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab"
                                                role="tab" aria-selected="false" @click.capture.stop>
                                                Tất cả
                                            </b-link>
                                        </li>
                                        <!-- <li class="nav-item">
                                            <b-link class="nav-link" data-bs-toggle="tab" href="#messages-tab"
                                                role="tab" aria-selected="true" @click.capture.stop>
                                                Messages
                                            </b-link>
                                        </li>
                                        <li class="nav-item">
                                            <b-link class="nav-link" data-bs-toggle="tab" href="#alerts-tab" role="tab"
                                                aria-selected="false" @click.capture.stop>
                                                Alerts
                                            </b-link>
                                        </li> -->
                                    </ul>
                                </div>
                            </div>
                            <div class="tab-content" id="notificationItemsTabContent">
                                <div class="tab-pane fade p-2 show active" id="all-noti-tab" role="tabpanel"
                                    style="height: 400px; overflow-y: auto;">
                                    <div class="notification-item"
                                        :class="_get(item, 'isRead', false) ? '' : 'bg-soft-danger'"
                                        v-for="(item, index) in notifications" :key="index"
                                        @click="getRedirectLink(_get(item, 'type'), _get(item, 'id'), _get(item, 'object.taskId'), _get(item, 'object.uuid'))">
                                        <Avatar :src="_get(item, 'sender.avatar', '')"
                                            :name="_get(item, 'sender.displayName', '')" size="xs" />
                                        <div>
                                            <div class="d-flex align-items-baseline gap-2">
                                                <div class="mb-0">
                                                    <div v-html="_get(item, 'content')"></div>
                                                </div>
                                                <span class="fs-10 text-muted text-nowrap">{{ _moment(_get(item,
                                                    'createdAt') *
                                                    1000).fromNow()
                                                    }}</span>
                                            </div>
                                            <div class="d-flex align-items-start flex-wrap gap-2">
                                                <div class="d-flex align-items-start gap-2">
                                                    <i class="ri-task-fill text-info"></i>
                                                    <small> {{ _get(item, 'object.title', '-') }}</small>
                                                </div>
                                                <b-badge
                                                    :variant="_get(taskStatus, `${_get(item, 'object.status')}.variant`)">
                                                    {{ _get(taskStatus, `${_get(item, 'object.status')}.label`) }}
                                                </b-badge>
                                            </div>
                                        </div>
                                    </div>
                                    <InfiniteLoading @infinite="infiniteHandler">
                                        <template #complete>
                                            <div v-if="_isEmpty(notifications)"
                                                class="p-4 d-flex gap-4 justify-content-center align-items-center flex-column">
                                                <img src="@/assets/images/file.png" style="width: 300px" />
                                                <span style="font-style: italic;">Không có thông báo nào</span>
                                            </div>
                                            <div v-else class="text-center py-2" style="font-style: italic;">
                                                Không còn thông báo nào.
                                            </div>
                                        </template>
                                        <template #spinner>
                                            <div class="p-4 text-center text-nowrap">Đang lấy dữ liệu</div>
                                        </template>
                                    </InfiniteLoading>
                                </div>
                                <!-- 
                                <div class="tab-pane fade py-2 ps-2" id="messages-tab" role="tabpanel"
                                    aria-labelledby="messages-tab">
                                    <SimpleBar data-simplebar style="max-height: 300px" class="pe-2">
                                        <div class="text-reset notification-item d-block dropdown-item">
                                            <div class="d-flex">
                                                <img src="@/assets/images/users/avatar-3.jpg"
                                                    class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                <div class="flex-1">
                                                    <b-link href="#!" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                            James Lemire
                                                        </h6>
                                                    </b-link>
                                                    <div class="fs-13 text-muted">
                                                        <p class="mb-1">
                                                            We talked about a project on linkedin.
                                                        </p>
                                                    </div>
                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i class="mdi mdi-clock-outline"></i> 30 min ago</span>
                                                    </p>
                                                </div>
                                                <div class="px-2 fs-15">
                                                    <input class="form-check-input" type="checkbox" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-reset notification-item d-block dropdown-item">
                                            <div class="d-flex">
                                                <img src="@/assets/images/users/avatar-2.jpg"
                                                    class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                <div class="flex-1">
                                                    <b-link href="#!" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                            Angela Bernier
                                                        </h6>
                                                    </b-link>
                                                    <div class="fs-13 text-muted">
                                                        <p class="mb-1">
                                                            Answered to your comment on the cash flow
                                                            forecast's graph 🔔.
                                                        </p>
                                                    </div>
                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i class="mdi mdi-clock-outline"></i> 2 hrs
                                                            ago</span>
                                                    </p>
                                                </div>
                                                <div class="px-2 fs-15">
                                                    <input class="form-check-input" type="checkbox" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-reset notification-item d-block dropdown-item">
                                            <div class="d-flex">
                                                <img src="@/assets/images/users/avatar-6.jpg"
                                                    class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                <div class="flex-1">
                                                    <b-link href="#!" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                            Kenneth Brown
                                                        </h6>
                                                    </b-link>
                                                    <div class="fs-13 text-muted">
                                                        <p class="mb-1">
                                                            Mentionned you in his comment on 📃 invoice
                                                            #12501.
                                                        </p>
                                                    </div>
                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i class="mdi mdi-clock-outline"></i> 10 hrs
                                                            ago</span>
                                                    </p>
                                                </div>
                                                <div class="px-2 fs-15">
                                                    <input class="form-check-input" type="checkbox" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="text-reset notification-item d-block dropdown-item">
                                            <div class="d-flex">
                                                <img src="@/assets/images/users/avatar-8.jpg"
                                                    class="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                                <div class="flex-1">
                                                    <b-link href="#!" class="stretched-link">
                                                        <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                                            Maureen Gibson
                                                        </h6>
                                                    </b-link>
                                                    <div class="fs-13 text-muted">
                                                        <p class="mb-1">
                                                            We talked about a project on linkedin.
                                                        </p>
                                                    </div>
                                                    <p class="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                        <span><i class="mdi mdi-clock-outline"></i> 3 days
                                                            ago</span>
                                                    </p>
                                                </div>
                                                <div class="px-2 fs-15">
                                                    <input class="form-check-input" type="checkbox" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="my-3 text-center">
                                            <b-button type="button" variant="soft-success">
                                                View All Messages
                                                <i class="ri-arrow-right-line align-middle"></i>
                                            </b-button>
                                        </div>
                                    </SimpleBar>
                                </div>

                                <div class="tab-pane fade p-4" id="alerts-tab" role="tabpanel"
                                    aria-labelledby="alerts-tab">
                                    <div class="w-25 w-sm-50 pt-3 mx-auto">
                                        <img src="@/assets/images/svg/bell.svg" class="img-fluid" alt="user-pic" />
                                    </div>
                                    <div class="text-center pb-5 mt-2">
                                        <h6 class="fs-18 fw-semibold lh-base">
                                            Hey! You have no any notifications
                                        </h6>
                                    </div>
                                </div> -->

                            </div>
                        </div>
                    </div>

                    <div class="dropdown ms-sm-3 header-item topbar-user">
                        <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                            <span class="d-flex align-items-center">
                                <Avatar :src="_get(profile, 'avatar')" :name="_get(profile, 'displayName')" size="xs" />
                                <span class="text-start ms-xl-2">
                                    <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">
                                        {{ _get(profile, 'displayName') }}
                                    </span>
                                    <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">
                                        {{ getRoles(_get(profile, 'roles', [])) }}
                                    </span>
                                </span>
                            </span>
                        </button>
                        <div class="dropdown-menu dropdown-menu-end">
                            <h6 class="dropdown-header">Chào {{ _get(profile, 'displayName') }}!</h6>
                            <router-link class="dropdown-item" to="/profile"><i
                                    class="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                                <span class="align-middle">Trang cá nhân</span>
                            </router-link>
                            <div class="dropdown-divider"></div>
                            <div class="dropdown-item cursor-pointer text-danger" @click="handleLogout"><i
                                    class="mdi mdi-logout text-danger fs-16 align-middle me-1"></i>
                                <span class="align-middle" data-key="t-logout">
                                    {{ isLogout ? 'Đang xử lý...' : 'Đăng xuất' }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="notification-item-mobile position-fixed top-0 end-0 bottom-0 start-0 bg-white"
                v-if="openNoti && isMobile" @click.stop>
                <div class="dropdown-head bg-secondary bg-pattern">
                    <div class="p-3">
                        <b-row class="align-items-center">
                            <b-col>
                                <h6 class="m-0 fs-16 fw-semibold text-white">
                                    Thông báo
                                </h6>
                            </b-col>
                            <b-col cols="auto" class="dropdown-tabs">
                                <div class="d-flex align-items-center gap-4">
                                    <div class="form-check form-switch form-switch-success">
                                        <input class="form-check-input" type="checkbox" role="switch"
                                            id="flexSwitchCheckChecked" v-model="unReadChecked">
                                        <label class="form-check-label text-white"
                                            style="transform: translateY(1px); user-select: none"
                                            for="flexSwitchCheckChecked">Chưa
                                            đọc</label>
                                    </div>
                                    <button class="bg-success text-white border-0 rounded" :disabled="isReadAll"
                                        @click="readAll">
                                        <div v-if="isReadAllSuccess" class="d-flex align-items-center gap-2">
                                            <i class="ri-check-line"></i>
                                            Đã đánh dấu
                                        </div>
                                        <div v-else> {{ isReadAll ? 'Đánh dấu...' : 'Đánh dấu tất cả đã đọc' }}
                                        </div>
                                    </button>
                                    <button class="border-0 rounded bg-danger text-white" @click="openNoti = false">
                                        <i class="ri-close-line"></i>
                                    </button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>

                    <div class="px-2 pt-2">
                        <ul class="nav nav-tabs dropdown-tabs nav-tabs-custom" data-dropdown-tabs="true"
                            id="notificationItemsTab" role="tablist">
                            <li class="nav-item bg-white rounded">
                                <b-link class="nav-link active" data-bs-toggle="tab" href="#all-noti-tab" role="tab"
                                    aria-selected="false">
                                    Tất cả
                                </b-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="tab-content" id="notificationItemsTabContent">
                    <div class="tab-pane fade p-2 show active" id="all-noti-tab" role="tabpanel"
                        style="height: 100vh; overflow-y: auto;">
                        <div class="notification-item" :class="_get(item, 'isRead', false) ? '' : 'bg-soft-danger'"
                            v-for="(item, index) in notifications" :key="index"
                            @click="getRedirectLink(_get(item, 'type'), _get(item, 'id'), _get(item, 'object.taskId'), _get(item, 'object.uuid'))">
                            <Avatar :src="_get(item, 'sender.avatar', '')" :name="_get(item, 'sender.displayName', '')"
                                size="xs" />
                            <div>
                                <div class="d-flex align-items-baseline gap-2">
                                    <div class="mb-0">
                                        <div v-html="_get(item, 'content')"></div>
                                    </div>
                                    <span class="fs-10 text-muted text-nowrap">{{ _moment(_get(item,
                                        'createdAt') *
                                        1000).fromNow()
                                        }}</span>
                                </div>
                                <div class="d-flex align-items-start gap-2">
                                    <div class="d-flex align-items-start gap-2">
                                        <i class="ri-task-fill text-info"></i>
                                        <small> {{ _get(item, 'object.title', '-') }}</small>
                                    </div>
                                    <b-badge :variant="_get(taskStatus, `${_get(item, 'object.status')}.variant`)">
                                        {{ _get(taskStatus, `${_get(item, 'object.status')}.label`) }}
                                    </b-badge>
                                </div>
                            </div>
                        </div>
                        <InfiniteLoading @infinite="infiniteHandler">
                            <template #complete>
                                <div v-if="_isEmpty(notifications)"
                                    class="p-4 d-flex gap-4 justify-content-center align-items-center flex-column">
                                    <img src="@/assets/images/file.png" style="width: 300px" />
                                    <span style="font-style: italic;">Không có thông báo nào</span>
                                </div>
                                <div v-else class="text-center py-2" style="font-style: italic;">
                                    Không còn thông báo nào.
                                </div>
                            </template>
                            <template #spinner>
                                <div class="p-4 text-center text-nowrap">Đang lấy dữ liệu</div>
                            </template>
                        </InfiniteLoading>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>


<style scoped>
._link:hover {
    text-decoration: underline;
}

.notification:hover .notification-box {
    display: block;
}

.notification-box-pc {
    position: absolute;
    right: 0;
    top: 100%;
    width: 450px;
}

.notification-item {
    display: flex;
    align-items: start;
    padding: 8px;
    cursor: pointer;
    gap: 8px;
    color: #000;
}

.notification-item:hover {
    background-color: #f5f5f5 !important;
}
</style>